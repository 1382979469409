<template>
  <div class="wrap-edit nzyxm">
    <el-card>
        <FloatTitBtn class="wr-else">
        <div class="edit-header">
          <span>{{ (form.XM_GUID ? "" : "新增") + "农转用项目" }}</span>
          <el-button type="primary" @click="save">保存</el-button>
        </div>
        </FloatTitBtn>
      <el-form
        ref="baseform"
        :model="form"
        label-width="130px"
        label-position="left"
      >
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="项目名称：" prop="XM_MC">
              <el-input v-model="form.XM_MC"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目编号：" prop="XM_BH">
              <el-input v-model="form.XM_BH"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="年度：" prop="ND">
              <el-input v-model="form.ND"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="行政区代码：" prop="XS_DM">
              <el-input v-model="form.XS_DM"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="土地坐落：" prop="TD_ZL">
              <el-input v-model="form.TD_ZL"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目类别：" prop="XM_LB">
              <el-input v-model="form.XM_LB"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="土地用途：" prop="TD_YT">
              <el-input v-model="form.TD_YT"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="行业分类：" prop="HY_FL">
              <el-input v-model="form.HY_FL"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否农民建房：" prop="NMJF_BZ">
              <el-input v-model="form.NMJF_BZ"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目面积：" prop="XM_MJ">
              <el-input-number
                v-model="form.XM_MJ"
                :min="0"
                :controls="false"
              />
              <span class="unit">平方米</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="农用地面积：" prop="NYD_MJ">
              <el-input-number
                v-model="form.NYD_MJ"
                :min="0"
                :controls="false"
              />
              <span class="unit">平方米</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="耕地面积：" prop="GD_MJ">
              <el-input-number
                v-model="form.GD_MJ"
                :min="0"
                :controls="false"
              />
              <span class="unit">平方米</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="建设用地面积：" prop="JSYD_MJ">
              <el-input-number
                v-model="form.JSYD_MJ"
                :min="0"
                :controls="false"
              />
              <span class="unit">平方米</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="未利用地面积：" prop="WLYD_MJ">
              <el-input-number
                v-model="form.WLYD_MJ"
                :min="0"
                :controls="false"
              />
              <span class="unit">平方米</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="征收集体面积：" prop="ZSJT_MJ">
              <el-input-number
                v-model="form.ZSJT_MJ"
                :min="0"
                :controls="false"
              />
              <span class="unit">平方米</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用集体面积：" prop="SYJT_MJ">
              <el-input-number
                v-model="form.SYJT_MJ"
                :min="0"
                :controls="false"
              />
              <span class="unit">平方米</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="供地面积：" prop="GY_MJ">
              <el-input-number
                v-model="form.GY_MJ"
                :min="0"
                :controls="false"
              />
              <span class="unit">平方米</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注：" prop="BZ">
              <el-input v-model="form.BZ" type="textarea" :rows="2"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="关系备注：" prop="GXBZ">
              <el-input
                v-model="form.GXBZ"
                type="textarea"
                :rows="2"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="园地面积：" prop="YD_MJ">
              <el-input-number
                v-model="form.YD_MJ"
                :min="0"
                :controls="false"
              />
              <span class="unit">平方米</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="林地面积：" prop="LD_MJ">
              <el-input-number
                v-model="form.LD_MJ"
                :min="0"
                :controls="false"
              />
              <span class="unit">平方米</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="草地面积：" prop="CD_MJ">
              <el-input-number
                v-model="form.CD_MJ"
                :min="0"
                :controls="false"
              />
              <span class="unit">平方米</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="农村道路面积：" prop="NCDL_MJ">
              <el-input-number
                v-model="form.NCDL_MJ"
                :min="0"
                :controls="false"
              />
              <span class="unit">平方米</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="开拓时期面积：" prop="KTSQ_MJ">
              <el-input-number
                v-model="form.KTSQ_MJ"
                :min="0"
                :controls="false"
              />
              <span class="unit">平方米</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="其他农用地：" prop="QTNYD_MJ">
              <el-input-number
                v-model="form.QTNYD_MJ"
                :min="0"
                :controls="false"
              />
              <span class="unit">平方米</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import FloatTitBtn from '../../components/FloatTitBtn.vue';
export default {
  components: { FloatTitBtn },
  data() {
    return {
      form: {
        XM_GUID: "",
        BP_GUID: "",
        BJID: "",
        XM_MC: "",
        XM_BH: "",
        ND: "",
        XS_DM: "",
        TD_ZL: "",
        XM_LB: "",
        TD_YT: "",
        HY_FL: "",
        NMJF_BZ: "",
        XM_MJ: undefined,
        NYD_MJ: undefined,
        GD_MJ: undefined,
        JSYD_MJ: undefined,
        WLYD_MJ: undefined,
        ZSJT_MJ: undefined,
        SYJT_MJ: undefined,
        GY_MJ: undefined,
        BZ: "",
        GXBZ: "",
        YD_MJ: undefined,
        LD_MJ: undefined,
        CD_MJ: undefined,
        NCDL_MJ: undefined,
        KTSQ_MJ: undefined,
        QTNYD_MJ: undefined,
      },
    };
  },
  methods: {
    async fetch() {
      try {
        await this.$ajax
          .get(
            this.$appConfig.apiUrls.ywxtApi +
              "/api/YW/getnzynzyxm?XM_GUID=" +
              this.form.XM_GUID
          )
          .then((response) => {
            if (response.data.IsSuccess) {
              this.form = response.data.Data;
            } else {
              throw response.data.ErrorMessage;
            }
          });

        const routeTags = this.$router.routeTags;
        if (
          this.form.XM_GUID &&
          routeTags &&
          !routeTags.some((tag) => tag.meta.nzy == this.form.XM_GUID)
        ) {
          const tag = routeTags[routeTags.length - 1];
          tag.meta.title = this.form.XM_BH;
          tag.meta.nzy = this.form.XM_GUID;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async save() {
      let me = this;
      try {
        await this.$ajax
          .post(
            this.$appConfig.apiUrls.ywxtApi + "/api/YW/AddorUpdatnzynzyxm",
            this.form
          )
          .then(function (response) {
            if (response.data.IsSuccess) {
              me.form.XM_GUID = response.data.Data;
              ElMessage({
                type: "success",
                message: "保存成功!",
              });
            } else {
              ElMessage({
                type: "info",
                message: "保存失败!",
              });
              console.log(response.data.ErrorMessage);
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    loadForm() {
      this.$refs.baseform.resetFields();
      this.form.XM_GUID = this.$route.query.id ?? "";
      this.form.BP_GUID = this.$route.query.bpid ?? "";
      this.form.BJID = this.$route.query.instanceId ?? "";
      if (this.form.XM_GUID) {
        this.fetch();
      }
    },
  },
  mounted() {
    this.loadForm();
  },
  watch: {
    $route() {
      if (this.$route.name === "nzyxm") {
        this.loadForm();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form{
  margin-top: 50px;
}
.wr-else{
  width: calc(100% - 77px)
}
.wrap-edit {
  height: 100%;
  overflow-y: scroll;
}
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



.el-input-number {
  flex: 1;
}

// .el-form-item {
//   margin: 11px 0;
// }
</style>

<style lang="scss">
.nzyxm {
  .el-form-item__content {
    display: flex;
    align-items: center;
  }



  input[min] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    text-align: start;
  }
}
</style>
